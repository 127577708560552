@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "CormorantGaramond";
  src: url("/public/fonts/CormorantGaramond.ttf") format("truetype");
}

@font-face {
  font-family: "Liana";
  src: url("/public/fonts/Liana.ttf") format("truetype");
}

@font-face {
  font-family: "Manrope";
  src: url("/public/fonts/Manrope.ttf") format("truetype");
}

@layer utilities {
  .bg-clip-text {
    background-clip: text;
    -webkit-background-clip: text;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
  background-color: transparent !important;
}

::-webkit-scrollbar {
  background-color: transparent !important;
  width: 10px !important;
}

::-webkit-scrollbar-track {
  background-color: transparent !important;
}

::-webkit-scrollbar-thumb {
  background-color: #d0ba9a;
  width: 10px !important;
  border-radius: 20px;
}

.PhoneInput {
  width: 270px;
}

.PhoneInputInput {
  height: 44px;
  padding: 8px 12px;
  border-radius: 0.5rem;
  border-width: 2px;
  border-color: rgb(208 213 221);
}

.PhoneInputCountry {
  margin-right: 50px;
}
